import Autosave from 'stimulus-rails-autosave';
import { Dropdown, Modal } from 'tailwindcss-stimulus-components';
import { registerControllers } from 'stimulus-vite-helpers';
import TextareaAutogrow from 'stimulus-textarea-autogrow';
import { Alert } from 'tailwindcss-stimulus-components'

import { stimulus } from '~/init';

stimulus.register('alert', Alert)
stimulus.register('autosave', Autosave);
stimulus.register('dropdown', Dropdown);
stimulus.register('modal', Modal);
stimulus.register('textarea-autogrow', TextareaAutogrow);

const controllers = import.meta.glob('./*_controller.js', { eager: true });
registerControllers(stimulus, controllers);
